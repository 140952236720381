<template>
  <v-container fluid>
    <div class="hello">
      <div class="card">
        <div class="hello">
          <div class="grid">
            <v-alert type="error" class="ma-3" :value="isError" dense text>
              Incorrect Email or password
            </v-alert>
            <div class="logo center">
              <font-awesome-icon icon="quidditch" class="icon" />
              <span class="label"><b> Quidditch</b></span>
            </div>
            <div class="grid">
              <v-text-field
                name="name"
                label="Email"
                outlined
                class="mx-2"
                color="info"
                type="text"
                v-model="data.email"
              ></v-text-field>
              <v-text-field
                name="name"
                label="Password"
                outlined
                color="info"
                class="mx-2"
                type="password"
                v-model="data.password"
              ></v-text-field>
              <div class="pa-4">
                <v-btn
                  block
                  elevation="2"
                  color="purple darken-2 white--text"
                  @click="Login"
                  >Login</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      isError: false,
      data: {
        email: "",
        password: "",
      },
    };
  },

  methods: {
    Login() {
      this.$store.dispatch("userLogin", this.data).then((d) => {
        if (!d.status) {
          this.isError = true;
        } else this.$router.push({ path: "dashboard/main" });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello {
  display: flex;
  justify-content: center;
}
</style>
